import clsx from "clsx";
import Image from "next/image";

export const Logo = ({ classNameContainer, isCollapsed }: { classNameContainer?: string; isCollapsed?: boolean }) => {
  return (
    <div
      className={clsx(
        "relative z-[999999999999] flex h-[40px] items-center gap-2",
        isCollapsed
          ? "w-[40px] transition-width group-hover/nav:w-[100px] group-hover/nav:delay-500 group-hover/nav:lg:w-[120px]"
          : "w-[100px] lg:w-[120px]",
        classNameContainer
      )}
    >
      <Image
        src="/logo-full.svg"
        alt="soldave logo"
        fill
        className={
          isCollapsed
            ? " block opacity-0 transition-opacity group-hover/nav:opacity-100 group-hover/nav:delay-500"
            : "block"
        }
      />
      <Image
        src="/logo-small.svg"
        alt="soldave logo"
        fill
        className={
          isCollapsed
            ? "block opacity-100 transition-opacity group-hover/nav:opacity-0 group-hover/nav:delay-500"
            : "hidden"
        }
      />
    </div>
  );
};

export default Logo;
